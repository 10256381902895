import { FormatDateOptions, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { PartnerDetails, trialDurationMap } from '@/components/dtcPartnerships/shared/types';
import { useApi } from '@/hooks/api';
import { usePricesState, usePurchaseParamsState } from '@/hooks/store';
import { setPrices } from '@/store/prices/actions';
import { setPurchaseParams } from '@/store/purchaseParams/actions';
import { PurchaseType, PlanCadence } from '@/store/purchaseParams/types';
import { putPartnerSubscribe } from '@/utils/endpoints';

import getDTCPartners from './data';
import messages from './messages';
import { DTCPartnerData, GetPartnerData } from './types';

const dateFormatting: FormatDateOptions = {
	dateStyle: 'medium',
};

export const _getDTCOfferHeader = (_data: PartnerDetails) => {
	if (!_data.offer_details) return messages.offerMessage;
	if (_data.name.toLowerCase() === 'aarp') return messages.aarp_headline;

	const {
		offer_details: { percent_off, trial_duration },
	} = _data;
	if (percent_off && trial_duration) {
		return messages.partnershipOfferMessageDiscountAndTrial;
	}
	if (trial_duration) {
		return messages.partnershipOfferMessageTrial;
	}
	if (percent_off) {
		return messages.partnershipOfferMessageDiscount;
	}
	return messages.partnershipOfferMessageNoDiscountAndNoTrial;
};

export function useDTCPartners(): {
	getDTCPartnerData: GetPartnerData;
	checkPartnerUserEligibility: (partner: PartnerDetails | null, code: string) => Promise<boolean>;
} {
	const { formatDate } = useIntl();
	const prices = usePricesState();
	const purchaseParams = usePurchaseParamsState();
	const dispatch = useDispatch();
	const apiRequest = useApi();

	function _getDate(date: string) {
		return formatDate(new Date(date), dateFormatting);
	}

	function _configureDTCPartnership(partnerApiResponse: PartnerDetails): void {
		const { offer_details } = partnerApiResponse;
		const planDuration: PlanCadence = offer_details?.billing_plan?.includes('1m') ? 'monthly' : 'yearly';

		const newPrice = {
			...prices,
			current: {
				...prices.current,
				...(offer_details?.price ? { [planDuration]: offer_details?.price } : {}),
				...(offer_details?.amount_due ? { amount_due: offer_details?.amount_due } : {}),
			},
		};

		const updatedPurchaseParams = {
			...purchaseParams,
			percentOff: offer_details?.percent_off,
			plan: planDuration,
		};

		if (offer_details?.trial_duration) {
			const updatedPurchaseType: PurchaseType = {
				type: 'freetrial',
				duration: trialDurationMap[offer_details.trial_duration].duration,
				units: trialDurationMap[offer_details.trial_duration].unit,
			};
			updatedPurchaseParams.purchaseType = {
				...updatedPurchaseParams.purchaseType,
				...updatedPurchaseType,
			};
		}
		dispatch(setPurchaseParams(updatedPurchaseParams));
		dispatch(setPrices(newPrice));
	}

	function getDTCPartnerData(data: PartnerDetails): DTCPartnerData | null {
		const dtcPartner: DTCPartnerData = getDTCPartners(data);
		dtcPartner.logo = data.logo_url && data.logo_url.length > 0 ? data.logo_url : dtcPartner.logo;
		dtcPartner.partnerTerms = data.offer_details?.terms ?? dtcPartner.partnerTerms;
		dtcPartner.endDate = _getDate(data.contract_expires_at || '');
		dtcPartner.messages.activate = dtcPartner.messages.activate ?? _getDTCOfferHeader(data);
		_configureDTCPartnership(data);
		return dtcPartner;
	}

	async function checkAARPPartnerUserEligibility(aarp_id: string): Promise<boolean> {
		try {
			const response: { data: { valid: boolean } } = await apiRequest({
				endpoint: 'aarp/register',
				method: 'POST',
				body: {
					aarp_id,
				},
			});

			return response.data.valid;
		} catch (error) {
			throw new Error(error);
		}
	}

	async function checkStandardPartnerUserEligibility(partner: PartnerDetails, code: string) {
		const partnerInfo = {
			partnerId: partner?.id,
			partnerUserId: code,
		};
		try {
			await putPartnerSubscribe(partnerInfo);
			return true;
		} catch (err) {
			if (err?.data.error.code === 'payment_required') {
				return true;
			}
			throw new Error(err);
		}
	}

	async function checkPartnerUserEligibility(partner: PartnerDetails | null, code: string): Promise<boolean> {
		if (!partner) return false;
		return partner?.name.toLowerCase() === 'aarp'
			? await checkAARPPartnerUserEligibility(code)
			: await checkStandardPartnerUserEligibility(partner, code);
	}

	return {
		getDTCPartnerData,
		checkPartnerUserEligibility,
	};
}
